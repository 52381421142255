@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");
* {
  background-color: rgb(235, 183, 42);
  margin: 0px;
  padding: 0px;
  color: black;
  font-family: "Quicksand", sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  padding: 96px;
}
